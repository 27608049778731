<template>
  <div class="titleWrap" :style="titleWrapStyle">
    <span class="title" :style="titleStyle">{{title}}</span>
    <div class="gap" :style="gapStyle"></div>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: "m-title-gap-bar",
  props: {
    title: {
      type: String,
      default:'名称'
    },
    titleWrapWidth: {
      type: Number,
      default: 527
    },
    titleWrapHeight: {
      type: Number,
      default: 35
    },
    titleWidth: {
      type: Number,
      default: 181
    },
    titleHeight: {
      type: Number,
      default: 35
    },
    titleColor:{
      type: String,
      default: "#333"
    },
    titleBgColor: {
      type: String,
      default: "#fffbf2"
    },
    titleFontSize:{
      type:Number,
      default:33
    },
    gapWidth: {
      type: Number,
      default: 519
    },
    gapHeight: {
      type: Number,
      default: 4
    },
    gapColor: {
      type: String,
      default: "#23b2e8"
    }
  },
  computed: {
    titleWrapStyle() {
      let obj = {
        width: this.titleWrapWidth + "px",
        height: this.titleWrapHeight + "px"
      };
      return obj;
    },
    titleStyle() {
      let obj = {
        width: this.titleWidth + "px",
        height: this.titleHeight + "px",
        color:this.titleColor,
        backgroundColor: this.titleBgColor,
        fontSize:this.titleFontSize,
        lineHeight:this.titleHeight + "px"
        
      };
      return obj;
    },
    gapStyle() {
      let obj = {
        width: this.gapWidth + "px",
        height: this.gapHeight + "px",
        backgroundColor: this.gapColor,
        marginTop:Math.round(this.titleWrapHeight/2)+"px"
      };
      return obj;
    }
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
.titleWrap {

position:relative;
  text-align: center;
  border:1px solid transparent;
  > .title {
    position: absolute;
    display: block;

    // font-size: 33px;
    font-weight: bold;

    left: 0;
    right: 0;
    margin: auto;
  }
  > .gap {
    width: 519px;
    height: 4px;
    margin-top: 15px;
  }
}
</style>

<template>
  <div class="contentWrap ifocus_shadow_gray">
    <div class="imgWrap maskWrapD layout_img">
      <img :src="baseUrl+resData.main_image_id" alt />
    </div>
    <div class="layout_info">
      <span class="title">{{title}}</span>
      <div class="starsWrap bottom_gap">
        <div class="stars">
          <m-star-bar
            class="starBar"
            title="推荐指数："
            :titleFontSize="14"
            titleColor="#000"
            :marginRight="0"
            :num="resData.star"
          ></m-star-bar>
        </div>
      </div>
      <div class="address bottom_gap">
        <span class="subtitle">餐厅简介：</span>
        <span class="summary textline">{{resData.restaurant_info}}</span>
      </div>
      <div class="address bottom_gap">
        <span class="subtitle">餐厅地址：</span>
        <span class="summary">{{resData.restaurant_addr}}</span>
      </div>
      <div class="btn trans" @click="todetail">查看详情</div>

      <!-- 招牌菜 题目 -->
      <m-title-gap-bar
        class="titleGap"
        title="招牌菜"
        titleBgColor="#fff"
        titleColor="#3e3e3e"
        gapColor="#fbb92a"
        :titleWrapWidth="851"
        :titleWidth="367"
        :titleFontSize="19"
        :gapWidth="851"
      ></m-title-gap-bar>

      <!-- 轮播图 -->
      <div class="c_pics g1">
        <div class="swiperWrap swiper1">
          <swiper
            id="a1"
            class="swiper-container"
            :navigation="{nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev'}"
            :autoplay="false"
            slidesPerView="3"
            :loop="true"
          >
            <swiper-slide class="swiper-slide" v-for="(banner, index) in imgList" :key="index">
              <img style="width:267px; height: 185px" :src=" baseUrl+banner" alt />
            </swiper-slide>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import TitleGapBar from "@/components/TitleGapBar";

import SwiperCore, { Navigation, Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
// import style (>= Swiper 6.x)
import "swiper/swiper-bundle.css";
SwiperCore.use([Navigation, Pagination, Autoplay]);

import StarBar from "@/components/starBar";

import config from "@/utils/config";
import { checkIsNumber } from "@/utils/util";

export default {
  name: "m-food-bar",
  components: {
    [TitleGapBar.name]: TitleGapBar,
    Swiper,
    SwiperSlide,
    [StarBar.name]: StarBar
  },
  props: {
    title: {
      type: String,
      default: "村民食堂"
    },
    resData: {
      type: Object,
      default: function() {
        return {
          main_image_id: "",
          title: "",
          restaurant_info: "",
          restaurant_addr: "",
          star: 4,
          rotation_chart: ""
        };
      }
    }
  },
  computed: {
    baseUrl() {
      return config.webBaseUrl;
    },
    imgList() {
      // console.log("imgList:", this.resData);
      let list = this.resData.rotation_chart.split(",");
      if (list.length > 0) {
        return list;
      }
      console.log("imgList:", list);
      return new Array();
    }
  },
  data() {
    return {
      banners: [
        "food.png",
        "food.png",
        "food.png",
        "food.png",
        "food.png",
        "food.png",
        "food.png",
        "food.png",
        "food.png",
        "food.png"
      ]
    };
  },

  methods: {
    todetail() {
      this.$emit("orderclick");
    }
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";

.c_pics {
  height: 202px;
  background: $main-white;
  padding-bottom: 30px;

  .swiperWrap {
    // margin:0 45px;
    // position:relative;
    > .swiper-container {
      padding-left: 37px !important;
      padding-right: 10px !important;
      height: 185px;
    }
    .swiper-button-prev,
    .swiper-button-next {
      width: 32px;
      height: 205px;
      top: 0;
      margin-top: 0;
      // background:$main-blue;
      border-radius: 2px;
      // &:hover{
      //   background:$main-blueDark;
      // }
    }

    .swiper-button-prev {
      left: 0;
      background: #fff;
    }
    .swiper-button-next {
      right: 0;
      background: #fff;
    }
    .swiper-button-prev:after {
      color: $main-white;
      background-image: url(~@/assets/image/food/arrow_right.png) !important;
      background-size: 100% 100%;
      width: 32px;
      height: 65px;
      background-repeat: no-repeat;
      font-family: none;
      content: "";
    }

    .swiper-button-next:after {
      color: $main-white;
      background-image: url(~@/assets/image/food/arrow_left.png) !important;
      background-size: 100% 100%;
      width: 32px;
      height: 65px;
      background-repeat: no-repeat;
      font-family: none;
      content: "";
    }
    .swiper-container {
      width: 879px;
      padding-left: 10px;
      background: $main-white;
      --swiper-theme-color: #ff6600;
      --swiper-navigation-color: #90c320; /* 单独设置按钮颜色 */
      --swiper-navigation-size: 40px; /* 设置按钮大小 */
    }
  }
}

.swiper-container {
  --swiper-theme-color: #ff6600;
  --swiper-navigation-color: #3e3e3e; /* 单独设置按钮颜色 */
  --swiper-navigation-size: 40px; /* 设置按钮大小 */
}

.bottom_gap {
  display: table;
  margin-bottom: 9px;
}
.contentWrap {
  position: relative;
  box-sizing: border-box;
  width: 1400px;
  background: #fff;
  overflow: hidden;
  padding: 24px 19px 24px 30px;
  > .layout_img {
    display: inline-block;
    position: relative;
    width: 424px;
    height: 449px;
    margin-right: 58px;
    background: $main-gray;
    > img {
      width: 100%;
      height: 100%;
    }
  }
  > .layout_info {
    position: relative;
    display: inline-block;
    width: 855px;
    height: 442px;
    padding-top: 7px;
    text-align: left;
    vertical-align: bottom;
    box-sizing: border-box;
    > span {
      display: block;
      color: $main-black;
      font-size: 14px;
      font-weight: bold;
    }
    > .title {
      font-size: 29px;
      font-weight: bold;
      margin-top: 11px;
    }
    .subtitle {
      display: table-cell;
      width: 70px;
      vertical-align: middle;
      color: $main-black;
      font-size: 14px;
      font-weight: bold;
    }
    .starsWrap {
      margin-top: 25px;
      .stars {
        display: inline-block;
      }
    }
    .summary {
      display: table-cell;
      // height: 32px;
      font-size: 14px;
      width: calc(100% - 70px);
      vertical-align: middle;
      overflow: hidden;
    }
    > .btn {
      position: absolute;
      top: 20px;
      right: 30px;
      padding: 19px 34px;
      font-size: 13px;
      color: $main-white;
      font-weight: bold;
      background: $main-yellow;
      box-sizing: border-box;
      border: 1px solid $main-yellow;
      border-radius: 5px;
      &:hover {
        color: $main-yellow;
        background: $main-white;
        border: 1px solid $main-yellow;
      }
    }
    > .c_pics {
      height: 185px;
      margin-left: -37px;
    }
  }
}
</style>
